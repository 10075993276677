<template>
  <b-modal :visible="show" :hide-header="true" centered id="modal-orientacao-plano" size="md"
    :hide-footer="true">
    <div class="float-right"><img @click="hide" class="close-modal" src="img/x-modal.svg" /></div>
    <div class="text-justify mt-3">
      <h5 class="bold-modal">Valores da cotação</h5>
      <div class="mb-2">
        O valor da bike apresentado na simulação, pode sofrer uma variação após a vistoria e, consequentemente, o valor do seu seguro pode mudar.
      </div>
      <div class="mb-2">
        Isso acontece porque o valor do seguro é baseado no valor de mercado da bicicleta, estado de conservação, ano de
        fabricação, marca e modelo.
      </div>
      <div>
        Então, após a vistoria online, a Bike Inspec, empresa parceira especializada em avaliar bicicletas, irá
        identificar a situação atual da sua bike e emitir um parecer indicando quanto ela realmente vale, só assim nós
        poderemos calcular o valor real do seguro.
      </div>

      <div class="center-modal mt-5">
        <button type="button" class="continue-btn-modal" @click="hide"> Entendi </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["show", "valorBike"],
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
<style>
#modal-orientacao-plano___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 25px;
  padding-top: 15px;
  color: #78849E;
}

.bold-modal {
  font-weight: 700;
  color: #425994;
}

.close-modal {
  width: 15px;
  cursor: pointer;
}

strong {
  font-weight: 600;
  color: #425994;
}

.center-modal {
  text-align: center;
}

.continue-btn-modal {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
</style>
